.App {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 14;
}

body {
  background-image: url('spr_1.jpg');
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
  height: 100vh; 
  margin: 0; 
}

html, body {
  height: 100vh;
  margin: 0;
}

.top-right-image {
  position:fixed;
  top: 0px;
  right: 0px;
  z-index: 1;
}

.overlay-text {
  position: fixed;
  right: 15px;
  bottom: 20px;
  font-size: 14px;
  color: #ffffff;
  background-color: #e6e4e43c;
  padding: 5px;
  z-index: 1001;
}

.bottom-right-text {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 600px;
  text-align:right;
  line-height: 80%;
  font-size: 122px; 
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: black; 
  z-index: 1000; 
}

/* Adjustments for tablet */
@media (max-width: 768px) {
  .bottom-right-text {
    text-align:right;
    line-height: 80%;
    font-size: 100px;  /* Smaller text for tablet */
    width: 400px;
    bottom: 5px;
    right: 10px;
  }
}

/* Adjustments for mobile */
@media (max-width: 480px) {
  .bottom-right-text {
    text-align:right;
    line-height: 80%;
    font-size: 100px; /* Even smaller text for mobile */
    font-weight: bold;
    width: 250px;
    bottom: 5px;
    right: 5px;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 45.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  position: absolute;
}

.grey-text {
  color: #747474;
}

.light-grey-text {
  color: #c1bfbf;
}

.button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  margin: 5px;
  transition: background-color 0.3s ease; 
}

.button:hover {
  background-color: #fab508;
  color: #000000; 
}

.button:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5); /* A light glow effect */
}

.button-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  width: 250px;
  text-align: right;
}

@media (max-width: 768px) {

  .button-container {
    top: 5px;
    right: 5px;
    width: auto;
  }

  .button-container button {
    font-size: 20px; /* Smaller font size for the buttons on small screens */
  }
} 

.overlay-background {
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 1001; /* over images, under buttons */
}

.information-card {
  position: fixed;
  left: 5vw;
  top: 25vh;
  background-color: #ffffffa3;
  color: #000000;
  z-index: 1004;
  width: 60vw;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 1s ease;
  pointer-events: none;
  -webkit-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
}

.information-card.visible {
  opacity: 1;
  pointer-events: all; /* Reactivate pointer events when the modal is visible */
}


.modal-content {
  position: relative;
  overflow: auto;
  height: 550px;
}

/* Style the scrollbar itself (Webkit) */
.modal-content::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.modal-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the tracking area */
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #f1f1f1; /* creates padding around scroll thumb */
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scroll thumb when hovered over */
}

/* Style the scrollbar itself (Firefox) */
.modal-content {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}

.close-button {
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
}

.close-button:hover {
  color: #fab508;
}

.orange-text {
  color: #ffb804;
  background-color: #555;
}

.information-card-title {
  font-size: 22px;
  font-weight: bold;

}

.website-demo {
  width: 95%;
  z-index: 1003;
}

.leftwrap-image {
    width: 10%;
    float: left; 
    margin-right: 20px;
    z-index: 1003;
  
}

.overlay-background-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.lightbox-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%; 
  max-width: 640px; 
  background-color: #ffffff;
  z-index: 1051;
  opacity: 0;
  transition: opacity 1s ease;
  pointer-events: none;
  overflow-y: auto; 
  max-height: 80vh; 
  border-radius: 8px; 
}

.lightbox-modal.visible {
  opacity: 1;
  pointer-events: all;
}

.lightbox-image {
  width: 100%; 
  height: auto; 
  display: block; 
  margin: 0 auto; 
}

.book-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.book {
  flex-basis: 30%; 
  text-align: center; 
  margin: 10px; 
}

.book img {
  width: 100%; 
  max-width: 200px; 
  height: auto; 
}

.title {
  margin-top: 10px; 
}


@media (max-width: 768px) {
  .book {
    flex-basis: 100%; 
  }
}